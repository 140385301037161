$(document).ready(function () {
    $.cookieCuttr({
        cookieAnalytics: false,
        cookieMessage: "<div class='col-lg-8 col-12 text-sm-left text-center'>"+cookieText+"</div>",
        cookieAcceptButtonText: cookieAcceptButtonText
    });

    $( ".cc-cookies" ).append( "<div class='page-max-width'><div class='row justify-content-center'><div class='col-lg-10 row col-12 inner-cookie'></div></div></div>" );
    let cookiesElement = $( ".cc-cookies .page-max-width .row .inner-cookie" );
    cookiesElement.append( $( ".cc-cookies .col-lg-8" ));
    cookiesElement.append( $( "<div class='col-lg-3 col-12 text-center text-lg-right align-self-center'></div>" ) );
    $( ".cc-cookies .col-lg-3").append($(".cc-cookie-accept"));

    let url = window.location.href;

    if (jQuery.cookie('cc_cookie_accept') === "cc_cookie_accept" && jQuery.cookie('lib_alert_seen') !== '1' && url.match('knjiznica') === null) {
        // insert the code you do not want to run UNTIL cookies are accepted here
        let html =  `<div class="cc-cookies">
                        <div class='page-max-width'>
                            <div class='row justify-content-center'>
                                <div class='col-12 col-lg-9 inner-cookie alert-inner'>
                                    <div class="row align-items-center">
                                        <div class='col-12 col-lg-8 text-sm-left text-center alert-box'>${alertText}</div>
                                        <div class="col-2 d-lg-none"></div>
                                        <div class='col-8 col-lg-3 text-center text-lg-right'>
                                            <a href="/knjiznica?manual" class="cc-cookie-accept link-to-manual">${alertButtonText}</a>
                                        </div>
                                        <div class="col-2 col-lg-1">
                                            <i style="font-size: 2rem;cursor: pointer;" class="ml-2 close_lib_borrow_alert">&times;</i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>`;
        $('body').append(html);
        setTimeout(function (){
            $('.cc-cookies').show();
        },10000);
    }

    $('.link-to-manual').on('click touch',function(e){
        e.preventDefault();
        jQuery.cookie("lib_alert_seen", 1, {
            path: '/'
        });
        window.location.href = $(this).attr('href');
    });

    $('.close_lib_borrow_alert').on('click touch',function (e){
        e.preventDefault();
        jQuery.cookie("lib_alert_seen", 1, {
            path: '/'
        });
        $('.cc-cookies').hide();
    });
});
